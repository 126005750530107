import React from "react";
import { Await } from "react-router-dom";
import { ArticleContext } from "@app/types/Page";
import CustomError from "@components/Error/CustomError";
import { TRouteWithRedirect, useRouteContext } from "@sphtech/web2-core/ssr";
import { HttpStatusCode } from "axios";

import ArticleContent from "./Article/ArticleContent";
import CatchAllBuilderRoutes from "./Builder/CatchAllBuilderRoutes";
import { BuilderContext } from "./Builder/utils/types";
import StoryThreadPage from "./StoryThread/StoryThreadPage";
import { StoryThreadContext } from "./StoryThread/utilities/types";

export default function DefaultPageHandler(): React.ReactElement {
  const { context } =
    useRouteContext<
      TRouteWithRedirect<ArticleContext | StoryThreadContext, string>
    >();

  // StatusCode is not 200
  if (context && context.statusCode !== HttpStatusCode.Ok) {
    return <CustomError statusCode={context.statusCode} />;
  }

  return (
    <Await resolve={context.payload} errorElement={<CustomError />}>
      {(context: ArticleContext | StoryThreadContext | BuilderContext) => {
        const { kind, data } = context;

        // Load article page content.
        if (kind === "art") {
          return <ArticleContent data={data} />;
        }

        // Load storythread page content.
        if (kind === "storythread") {
          const { entity } = context;

          return <StoryThreadPage {...{ data, entity }} />;
        }

        // Load builder page content.
        if (kind === "builder") {
          return <CatchAllBuilderRoutes />;
        }

        // Default error page.
        return <CustomError statusCode={HttpStatusCode.NotFound} />;
      }}
    </Await>
  );
}
